import React from "react"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import track from "../../../components/track"

const HcpSitemap = () => {

  const pageTitle = 'Site map';
  const pageDescription = 'Site map';
  const pageName = "Site Map";

  const trackSitemap = trackString => {
    track("HCP Site Map page", "link click", "HCP Site Map-" + trackString);
  }

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="hcp" pageSection="hcp-sitemap" pageClass="sitemap" pageName={pageName}>
      <div className="container">
        <h3>Site map</h3>
        <p><Link to="/hcp/" id="hcp-sitemap-link-home" onClick={e => trackSitemap("Home")}>Home</Link></p>
        <p><Link to="/hcp/burden-of-oic/" id="hcp-sitemap-sublink-about1" onClick={e => trackSitemap("Burden of OIC")}>Burden of OIC</Link></p>
        <p><Link to="/hcp/efficacy/" id="hcp-sitemap-link-efficacy" onClick={e => trackSitemap("Efficacy")}>Efficacy</Link></p>
        <p><Link to="/hcp/safety/" id="hcp-sitemap-link-safety" onClick={e => trackSitemap("Safety")}>Safety</Link></p>
        <p><Link to="/hcp/dosing/" id="hcp-sitemap-link-dosing" onClick={e => trackSitemap("Dosing")}>Dosing</Link></p>
        <p className="list-header"><Link to="/hcp/savings/" id="hcp-sitemap-link-patient-savings" onClick={e => trackSitemap("Patient Savings")}>Patient Savings</Link></p>
      </div>
    </Layout>
  )
}

export default HcpSitemap
